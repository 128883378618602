import { onAuthStateChanged, User } from 'firebase/auth'
import { adminRoleEmails } from 'lib/role'
import React, { useContext, useEffect, useState } from 'react'
import { getAuth } from '.'

type Authenticated = {
  authenticated: true
  user: User
  idToken: string
  isAdmin: boolean
}
type Unauthenticated = {
  authenticated: false
  readonly user: undefined
  readonly idToken: undefined
  readonly isAdmin: false
}
export type AuthState = Authenticated | Unauthenticated

export const AuthContext = React.createContext<AuthState>({
  authenticated: false,
  user: undefined,
  idToken: undefined,
  isAdmin: false,
})

export function useAuth() {
  return useContext(AuthContext)
}

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const [init, setInit] = useState(false)
  const [authContext, setAuthContext] = useState<AuthState>({
    authenticated: false,
    user: undefined,
    idToken: undefined,
    isAdmin: false,
  })

  /**
   * firebaseの認証状態を購読
   */
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(
      getAuth(),
      (user) => {
        if (user) {
          user.getIdToken().then((idToken) => {
            setAuthContext({
              user,
              idToken,
              authenticated: true,
              isAdmin:
                user.email != null && adminRoleEmails.includes(user.email),
            })
            setInit(true)
          })
        } else {
          setAuthContext({
            user: undefined,
            authenticated: false,
            idToken: undefined,
            isAdmin: false,
          })
          setInit(true)
        }
      },
      (error) => {
        // TODO: エラーハンドリング
        console.error(error)
      }
    )
    return () => unsubscribe()
  }, [])

  /**
   * firebaseのidTokenを1分ごとに更新
   * TODO: 残り時間確認して必要であれば更新する
   */
  useEffect(() => {
    if (!authContext.authenticated) {
      return
    }
    const timerId = setInterval(() => {
      authContext.user.getIdToken(true).then((idToken) => {
        setAuthContext({
          ...authContext,
          idToken,
        })
      })
    }, 1000 * 60)
    return () => {
      clearInterval(timerId)
    }
  }, [authContext])
  return (
    <AuthContext.Provider value={authContext}>
      {init && children}
    </AuthContext.Provider>
  )
}
